import React, { useEffect } from "react";
import pdfA from './pdf/pdfE.pdf';
function PdfE() {
    useEffect(() => {
        const link = document.createElement('a');
        link.href = pdfA;
        link.target = '_blank';
        link.download = 'Eduardo Colin - Cydonia Engineering.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }, [])
}
export default PdfE;