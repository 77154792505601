import { useState } from "react";
import React from "react";
import { Carousel } from 'primereact/carousel';
import {Galleria} from 'primereact/galleria';
import PiePagina from '../pie/PiePagina';

import {Button} from 'primereact/button';
import {useNavigate} from 'react-router-dom';
import imgCar1 from './img/carrusel 1.png';
import imgCar2 from './img/carrusel 2.png';
import imgCar3 from './img/carrusel 3.png';

import imgCar4 from './img/carrusel 1-A.png';
import imgCar5 from './img/carrusel B.png';
import imgCar6 from './img/carrusel C.png';

import imgVeri from './img/validacion.png';
import imgSol from './img/soluciones.png';
import imgSis from './img/sistemas.png';
import imgCom from './img/comunicaciones.png';
import imgMis from './img/mision.png';
import imgVis from './img/vision.png';
import imgVal from './img/valores.png';
import imgWhat from './img/ico wh 2.png';


import imgsm1 from './img/Smooth-1.png';
import imgsm2 from './img/Smooth-2.png';
import imgsm3 from './img/Smooth-3.png';

import './css/inicioCarrusel.css';


function Inicio() {
    const navigate = useNavigate();

    const urlSmooth = ()=>{
        window.scrollTo(0, 0);
        navigate('/smooth');
    }
    const arrayImagen = [
        {
            url: imgCar4
        },
        {
            url: imgCar6
        },
        {
            url: imgCar5
        },
        {
            url: imgCar2
        },
        {
            url: imgCar3
        }
    ]
    const templateImgCarrusel = (data) => {
        return (
            <img src={data.url} alt={"test"} style={{ width: "100%" }} />
        )
    }
    return (
        <div class="card ">
            <div class="card-container ">
                <div className='block cardBack'>
                <Galleria value={arrayImagen} 
                    showThumbnails={false} 
                    showIndicators 
                    showItemNavigators
                    showIndicatorsOnItem={true} 
                    indicatorsPosition={'bottom'} 
                    item={templateImgCarrusel} 
                    autoPlay transitionInterval={5000}
                    circular
                    numVisible={arrayImagen.length}
                    />
                </div>
                
                <div className="block " style={{ overflow: 'hidden' }}>
                    <div className="grid parrafoTitulos" >

                        <div className="col-6 sm:col-12 cuadroImagen" >
                            <p style={{"font-size":"4rem","text-align":"center", marginBottom:"0px"}}><span style={{}}>#WHEREVER WE GO</span></p>
                        </div>
                    </div>
                    <div className="grid parrafoTitulos" >
                        <div className="col-6 sm:col-12 ">
                            <img src={imgsm1} style={{ width: '24rem' }}></img>
                            <img src={imgsm2} style={{ width: '24rem' }}></img>
                            <img src={imgsm3} style={{ width: '24rem' }}></img>
                        </div>
                    </div>
                    <div className="block parrafoTexto">
                        <p style={{"font-family":"Avenir Next - Medium","size":"28"}}>Smooth Operator es una plataforma multipropósito diseñada para llegar a lugares de difícil acceso en distintos entornos y proporcionar el auxilio requerido con el menor riesgo posible. </p>
                        <center><Button onClick={urlSmooth} style={{"background-color":"#030D80"}}>Explorar más</Button></center>
                        <p style={{"font-family":"Avenir Next - Medium","font-size":"28px"}}>Cydonia Enginnering es una organización que potencia el desarrollo de sus clientes y socios a través de proyectos que brindan soluciones integrales en distintas ramas de la ingenieria, control, automatización, software on deman, gestión industrial y de procesos, validación y pruebas, siendo esta última nuestro mayor nicho de negocio</p>
                    </div>
                    <div className="block " style={{ overflow: 'hidden' }}>
                        <div className="grid parrafoTitulos" >
                            <div className="col-6 sm:col-3 cuadroImagen" >
                                <img src={imgVeri} style={{ width: '90%' }}></img>
                                <p>Verificación y Validación</p>
                            </div>
                            <div className="col-6 sm:col-3 cuadroImagen" >
                                <img src={imgSol} style={{ width: '90%' }}></img>
                                <p>Soluciones Industriales</p>
                            </div>
                            <div className="col-6 sm:col-3 cuadroImagen" >
                                <img src={imgSis} style={{ width: '90%' }}></img>
                                <p>Sistemas y Desarollo Digital</p>
                            </div>
                            <div className="col-6 sm:col-3 cuadroImagen"  >
                                <img src={imgCom} style={{ width: '90%' }}></img>
                                <p>Comunicaciones y Electrónica</p>
                            </div>
                        </div>
                    </div>
                    <div className="grid parrafoTexto">
                        <div className="sm:col-12">
                            <p style={{"font-family":"Avenir Next - Medium","font-size":"28px"}}>La mejora continua y el trabajo en equipo nos han permitido colaborar en grandes proyectos y avanzar hacia el progreso. Consolidamos un equipo experto, innovador y altamente especializado en soliciones de ingeniería</p>
                        </div>
                    </div>
                    <div className="card parrafoTitulos" style={{ width: '100%' }}>
                        <div className="card-container">
                            <div className="inline-block m-3">
                                <img src={imgMis} style={{ width: '12rem' }}></img>
                                <p>Misión</p>
                            </div>
                            <div className="inline-block m-3">
                                <img src={imgVis} style={{ width: '12rem' }}></img>
                                <p>Visión</p>
                            </div>
                            <div className="inline-block m-3">
                                <img src={imgVal} style={{ width: '12rem' }}></img>
                                <p>Valores</p>
                            </div>
                        </div>

                    </div>
                    <div className="grid" style={{backgroundColor:'#93d500', padding:'0.5rem'}}>
                        <div className="sm:col-10 p-contactoWhats" >
                            <p>¿Necesitas una solución para tu proyecto? Solicita una asesoría con un especialista de Cydonia Enginnering</p>
                        </div>
                        <div className="sm:col-2 align-items-center">
                            <a  href="https://wa.me/525531235622"><img src={imgWhat} style={{width:'60%'}}></img></a>
                        </div>
                    </div>
                    <PiePagina></PiePagina>
                </div>
            </div>
        </div>
    );
}
export default Inicio;