import React from "react";
import imgWhat from '../inicio/img/ico wh 2.png'
import imgPMP from './img/logo project.png';
import imgGreen from './img/logo sigma.png';
import imgISTQB from './img/logo istqb.png';

function Certificacion() {
    return (
        <div className="card" >
            <div className="block" style={{ marginTop: '4rem' }}>
                <p className="textoPaginaValores">Los especialistas en Cydonia cuentas con certificaciones en:</p>
            </div>
            <div className="block" style={{ marginTop: '4rem', marginBottom:'4rem' }}>
                <div className="grid">
                    <div className="col-12 md:col-4">
                        <div className="flex flex-wrap card-container justify-content-center">
                            <div className="card textoRecuadrosValidacion" style={{ maxWidth: '70%' }}>
                                <img src={imgPMP} style={{ width: '100%' }}></img>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-4">
                        <div className="flex flex-wrap card-container justify-content-center">
                            <div className="card textoRecuadrosValidacion" style={{ maxWidth: '70%' }}>
                                <img src={imgGreen} style={{ width: '100%' }}></img>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-4">
                        <div className="flex flex-wrap card-container justify-content-center">
                            <div className="card textoRecuadrosValidacion" style={{ maxWidth: '70%' }}>
                                <img src={imgISTQB} style={{ width: '100%' }}></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid" style={{ backgroundColor: '#93d500', padding: '0.5rem' }}>
                <div className="sm:col-10 p-contactoWhats" >
                    <p>¿Necesitas una solución para tu proyecto? Solicita una asesoría con un especialista de Cydonia Enginnering</p>
                </div>
                <div className="sm:col-2 align-items-center">
                    <a href="https://wa.me/525540432455"><img src={imgWhat} style={{ width: '60%' }}></img></a>
                </div>
            </div>
        </div>

    )
}
export default Certificacion