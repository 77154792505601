import React from "react";

import imgBannerValidacion from './img/banner verificacion.png';
import imgVIngenieria from './img/v ingenieria.png';


import PiePagina from "../pie/PiePagina";
import Certificacion from "./certificacion";


function Validacion() {
    return (
        <div className="card">
            <div className="card-container" style={{ overflow: 'hidden' }}>
                <div className="block">
                    <img src={imgBannerValidacion} style={{ width: '100%' }}></img>
                </div>
                <div className="card-container" style={{ backgroundColor: '#f9f9f9'}}>
                    <div className="block" style={{ padding: '2rem' }}>
                        <p className="textoPaginaValores">Validamos que un producto, servicio o sistema cumple con las necesidades de clentes y usuarios. Se desarrolln proyectos de verificación para garantizar los estándares de calidad requeridos, así como el cumplimiento de alguna regulación u obtención de certificación.</p>
                    </div>
                    <div className="block">
                        <p>Etapas de desarrollo, validación y verificación de producto.</p>
                        <img src={imgVIngenieria} style={{ width: '90%' }}></img>
                    </div>
                    <div className="block">
                        <p className="textoPaginaValores">Brindamos soluciones en cada una de las etapas de desarrollo de producto usando distintos tipos de pruebas y metodologías que se adaptan a las necesidades de cada proyecto, entre las que se encuentran:</p>
                    </div>
                    <div className="block">
                        <div className="grid">
                            <div className="col-12 md:col-4">
                                <div className="flex flex-wrap card-container justify-content-center">
                                    <div className="card textoRecuadrosValidacion">
                                        <p>Aplicación de metodologías ágiles</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="flex flex-wrap card-container justify-content-center">
                                    <div className="card textoRecuadrosValidacion" style={{ maxWidth: '70%' }}>
                                        <p>Metodología ISTQB</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="flex flex-wrap card-container justify-content-center">
                                    <div className="card textoRecuadrosValidacion" style={{ maxWidth: '70%' }}>
                                        <p>Pruebas de caja negra y caja blanca</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="flex flex-wrap card-container justify-content-center">
                                    <div className="card textoRecuadrosValidacion" style={{ maxWidth: '70%' }}>
                                        <p>Generación  de matriz de pruebas</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="flex flex-wrap card-container justify-content-center">
                                    <div className="card textoRecuadrosValidacion" style={{ maxWidth: '70%' }}>
                                        <p>Pruebas regresivas y de experiencia de usuario</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="flex flex-wrap card-container justify-content-center">
                                    <div className="card textoRecuadrosValidacion" style={{ maxWidth: '70%' }}>
                                        <p>Bancos de pruebas semiautomáticos y automáticos</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <Certificacion></Certificacion>
                </div>
                
                <PiePagina></PiePagina>

            </div>
        </div>
    );
}
export default Validacion;