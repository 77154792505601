import React from "react";

import imgBanner from './img/banner comunicaciones.png';
import imgElec from './img/electronica.png';
import imgPuntos from './img/electro.png';
import Certificacion from "./certificacion";
import PiePagina from "../pie/PiePagina";

function Comunicacion() {
    return (
        <div className="card">
            <div className="card-container" style={{ backgroundColor: '#f9f9f9', overflow:'hidden' }}>
                <div className="block">
                    <img src={imgBanner} style={{ width: '100%' }}></img>
                </div>
                <div className="block textoParrafoSubProyectos" style={{padding:'2rem'}}>
                    <p>Ejecutamos proyectos de desarrollo e implementaciones integrales de sistemas electónicos especializados para la insdustria y laboratorios, desde  el diseño y desarrollo de prototipos de hardware y software hasta la implementación, el mantenimiento preventivo y correctivo de los mismo, de acuerdo con los requerimientos del cliente.</p>
                </div>
                <div className="block textoParrafoSubProyectos" style={{textAlign:'center'}}>
                    <p>Contamos con soluciones en:</p>
                </div>
                <div className="flex flex-wrap card-container justify-content-center">
                    <div className="card" style={{ maxWidth: '70%' }}>
                        <img src={imgElec} style={{ width: '100%' }}></img>
                    </div>
                </div> 
                <div className="block textoParrafoSubProyectos" style={{textAlign:'center'}}>
                    <p>Además de mejoras e innovaciones para:</p>
                </div>
                <div className="grid" style={{ paddingLeft: '10rem', paddingRight: '10rem' }}>
                    <div className="col-12 sm:col-2">
                        <img src={imgPuntos} className="imgMarcador"></img>
                    </div>
                    <div className="col-12 sm:col-10">
                        <div className="flex align-items-center flex-wrap card-container textoPuntos" style={{ height: '100%' }}>
                            <p>Adquisición de datos de maquinas industriales y equipos (Bancos de datos y Bancos de pruebas).</p>
                        </div>
                    </div>
                    <div className="col-12 sm:col-2">
                        <img src={imgPuntos} className="imgMarcador"></img>
                    </div>
                    <div className="col-12 sm:col-10">
                        <div className="flex align-items-center flex-wrap card-container textoPuntos" style={{ height: '100%' }}>
                            <p>Integración de sistemas GPS con adquisición de datos.</p>
                        </div>
                    </div>
                    <div className="col-12 sm:col-2">
                        <img src={imgPuntos} className="imgMarcador"></img>
                    </div>
                    <div className="col-12 sm:col-10">
                        <div className="flex align-items-center flex-wrap card-container textoPuntos" style={{ height: '100%' }}>
                            <p>Validación y optimización de redes móviles, WLAN, PAN, WAN.</p>
                        </div>
                    </div>
                    <div className="col-12 sm:col-2">
                        <img src={imgPuntos} className="imgMarcador"></img>
                    </div>
                    <div className="col-12 sm:col-10">
                        <div className="flex align-items-center flex-wrap card-container textoPuntos" style={{ height: '100%' }}>
                            <p>Redes convergentes</p>
                        </div>
                    </div>
                </div>
                <Certificacion></Certificacion>
                <PiePagina></PiePagina>
            </div>

        </div>
    )
}
export default Comunicacion;