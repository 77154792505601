import React from "react";
import imgBanner from './img/banner sistemas.png';
import imgSistemas from './img/software.png';
import imgSiem from './img/integraciones-08.png'
import imgLab from './img/integraciones-09.png'
import imgReact from './img/integraciones-10.png'
import imgSql from './img/integraciones-11.png'
import imgPhp from './img/integraciones-12.png'
import imgLaravel from './img/integraciones-13.png'
import imgAng from './img/integraciones-14.png'
import imgNet from './img/integraciones-15.png'
import Certificacion from "./certificacion";
import PiePagina from "../pie/PiePagina";

function Sistemas() {
    return (
        <div className="card" style={{overflow:'hidden'}}>
            <div className="card-container" style={{ backgroundColor: '#f9f9f9' }}>
                <div className="block">
                    <img src={imgBanner} style={{ width: '100%' }}></img>
                </div>
                <div className="block textoParrafoSubProyectos" style={{padding:'2rem'}}>
                    <p>Consientes de los avances tecnológicos, innovamos para diseñar e implementar proyectos en sistemas y desarrollo digital que permiten a las empresas migrar a la digitalización de sus procesos de forma escalada, ágil y a la medida de sus necesidades, brindando mayor productividad, reducción de costos, respuesta oportuna ante problemáticas específicas y la garantia de aputnar siempre a la calidad y mejora continua.</p>
                </div>
                <div className="block textoParrafoSubProyectos" style={{padding:'2rem', textAlign:'center'}}>
                    <p>Ponemos a disposición de clientes y socios soluciones personalizadas en:</p>
                </div>
                <div className="flex align-items-center justify-content-center flex-wrap card-container">
                    <img src={imgSistemas} style={{ width: '90%' }}></img>
                </div>
                <div className="block textoParrafoSubProyectos" style={{padding:'2rem', textAlign:'center'}}>
                    <p>Cydonia Engineering implementa y se adapta a distintas tecnologías:</p>
                </div>
                <div className="grid">
                    <div className="col-6 sm:col-3">
                        <div className="flex flex-wrap card-container justify-content-center">
                            <div className="card textoRecuadrosValidacion" style={{ maxWidth: '70%' }}>
                                <img src={imgSiem} style={{ width: '100%' }}></img>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 sm:col-3">
                        <div className="flex flex-wrap card-container justify-content-center">
                            <div className="card textoRecuadrosValidacion" style={{ maxWidth: '70%' }}>
                                <img src={imgLab} style={{ width: '100%' }}></img>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 sm:col-3">
                        <div className="flex flex-wrap card-container justify-content-center">
                            <div className="card textoRecuadrosValidacion" style={{ maxWidth: '70%' }}>
                                <img src={imgReact} style={{ width: '100%' }}></img>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 sm:col-3">
                        <div className="flex flex-wrap card-container justify-content-center">
                            <div className="card textoRecuadrosValidacion" style={{ maxWidth: '70%' }}>
                                <img src={imgReact} style={{ width: '100%' }}></img>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 sm:col-3">
                        <div className="flex flex-wrap card-container justify-content-center">
                            <div className="card textoRecuadrosValidacion" style={{ maxWidth: '70%' }}>
                                <img src={imgSql} style={{ width: '100%' }}></img>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 sm:col-3">
                        <div className="flex flex-wrap card-container justify-content-center">
                            <div className="card textoRecuadrosValidacion" style={{ maxWidth: '70%' }}>
                                <img src={imgLaravel} style={{ width: '100%' }}></img>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 sm:col-3">
                        <div className="flex flex-wrap card-container justify-content-center">
                            <div className="card textoRecuadrosValidacion" style={{ maxWidth: '70%' }}>
                                <img src={imgAng} style={{ width: '100%' }}></img>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 sm:col-3">
                        <div className="flex flex-wrap card-container justify-content-center">
                            <div className="card textoRecuadrosValidacion" style={{ maxWidth: '70%' }}>
                                <img src={imgNet} style={{ width: '100%' }}></img>
                            </div>
                        </div>
                    </div>
                </div>
                <Certificacion></Certificacion>
                <PiePagina></PiePagina>
            </div>
        </div>
    )
}
export default Sistemas;