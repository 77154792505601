import { useState, useRef } from "react";
import React from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import 'primeflex/primeflex.css';
import './css/menus.css';
import './css/contenedor.css';
import logo from './img/logo cydonia bco sin fondo.png';
import { Outlet, useNavigate } from 'react-router-dom';

import { Menubar } from 'primereact/menubar';

function Home() {
    const navigate = useNavigate();
    const items = [
        {
            label: 'Inicio',
            command: () => { navigate('./inicio') }
        },
        {
            label: '¿Quienes somos?',
            command: () => { navigate('./conocenos') }
        },
        /*{
            label: 'Proyectos integrales',
            command: (e)=>{navigate('./proyectos');},
        },*/
        {
            id: 'submenuPro',
            label: 'Proyectos integrales',
            expanded: true,
            command: (e) => {
                navigate('./proyectos');
                if (!document.getElementById('submenuPro').className.includes('p-menuitem-active'))
                    document.getElementById('submenuPro').className += ' p-menuitem-active';
                else
                    document.getElementById('submenuPro').className = 'p-menuitem';

            },
            //visible:true,
            items: [
                {
                    label: 'Verificación y validación',
                    command: () => {
                        navigate('./verificacion')
                        if (!document.getElementById('submenuPro').className.includes('p-menuitem-active'))
                            document.getElementById('submenuPro').className += ' p-menuitem-active';
                        else
                            document.getElementById('submenuPro').className = 'p-menuitem';
                    }

                },
                {
                    label: 'Soluciones industriales',
                    command: () => {
                        navigate('./soluciones')
                        if (!document.getElementById('submenuPro').className.includes('p-menuitem-active'))
                            document.getElementById('submenuPro').className += ' p-menuitem-active';
                        else
                            document.getElementById('submenuPro').className = 'p-menuitem';
                    }

                },
                {
                    label: 'Sistemas y desarrollo digital',
                    command: () => {
                        navigate('./sistemas');
                        if (!document.getElementById('submenuPro').className.includes('p-menuitem-active'))
                            document.getElementById('submenuPro').className += ' p-menuitem-active';
                        else
                            document.getElementById('submenuPro').className = 'p-menuitem';
                    }
                },
                {
                    label: 'Comunicación y electrónica',
                    command: () => {
                        navigate('./comunicaciones');
                        if (!document.getElementById('submenuPro').className.includes('p-menuitem-active'))
                            document.getElementById('submenuPro').className += ' p-menuitem-active';
                        else
                            document.getElementById('submenuPro').className = 'p-menuitem';
                    }
                }
            ]
        },
        {
            label: 'Contacto',
            command: () => {
                navigate('./contacto');
            }

        },
        {
            label: 'Blog',
        },
        {
            label: 'Smooth',
            command: () => {
                navigate('./smooth');
            }

        }
    ];

    const logoMenu = <img alt="logo" src={logo} className="mr-2 tam-logo" style={{ marginLeft: '3rem' }}></img>;

    return (
        <div class="card" style={{ backgroundColor: 'black', minHeight: '100vh' }} className="contenedorPrincipal">
            <div class="card-container">
                <div class="block text-center">
                    <Menubar start={logoMenu} model={items} style={{ borderRadius: 0 }} ></Menubar>
                </div>
                <div class="block text-center">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}
export default Home;
