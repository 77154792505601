import React from "react";

import imgBannerSolucion from './img/banner soluciones.png';
import imgEspc from './img/industrial.png';
import imgPunto from './img/marcador.png';
import imgDesarrollo from './img/desarrollos.png';
import Certificacion from "./certificacion";
import PiePagina from "../pie/PiePagina";

function Soluciones() {
    return (
        <div className="card" style={{ overflow: 'hidden' }}>
            <div className="card-container" style={{ backgroundColor: '#f9f9f9' }}>
                <div className="block">
                    <img src={imgBannerSolucion} style={{ width: '100%' }}></img>
                </div>
                <div className="block">
                    <p>Contamos con servicios de consultoría integral para realizar el diagnóstico e identificación de problemas, fallas y áreas de oportunidad en los distintos procesos de la industria para elaborar planes de mejora a la medida de sis necesidades, requerimieento y objetivos que aumenten la calidad de procesos y producto final con un reducción de costos y riesgos.</p>
                </div>
                <div className="block">
                    <p>Contamos con especialistas en:</p>
                </div>
                <div className="grid">
                    <div className="col-12 sm:col-5">
                        <div className="flex align-items-center justify-content-center flex-wrap card-container textoPuntos" style={{ height: '100%' }}>
                            <img src={imgEspc} className="textoRecuadrosValidacion" style={{ width: '90%' }}></img>
                        </div>

                    </div>
                    <div className="col-12 sm:col-7">
                        <div className="grid">
                            <div className="col-12 sm:col-2">
                                <img src={imgPunto} className="imgMarcador"></img>
                            </div>
                            <div className="col-12 sm:col-10">
                                <div className="flex align-items-center flex-wrap card-container textoPuntos" style={{ height: '100%' }}>
                                    <p>Reducción de scrap mediante la gestión, análisis y desarrollo de procesos</p>
                                </div>
                            </div>
                            <div className="col-12 sm:col-2">
                                <img src={imgPunto} className="imgMarcador"></img>
                            </div>
                            <div className="col-12 sm:col-10">
                                <div className="flex align-items-center flex-wrap card-container textoPuntos" style={{ height: '100%' }}>
                                    <p>Sistemas de gestión de calidad</p>
                                </div>
                            </div>
                            <div className="col-12 sm:col-2">
                                <img src={imgPunto} className="imgMarcador"></img>
                            </div>
                            <div className="col-12 sm:col-10">
                                <div className="flex align-items-center flex-wrap card-container textoPuntos" style={{ height: '100%' }}>
                                    <p>monitoreo, instalación y supervisión de controles en áreas críticas</p>
                                </div>
                            </div>
                            <div className="col-12 sm:col-2">
                                <img src={imgPunto} className="imgMarcador"></img>
                            </div>
                            <div className="col-12 sm:col-10">
                                <div className="flex align-items-center flex-wrap card-container textoPuntos" style={{ height: '100%' }}>
                                    <p>Pruebas y monitoreo de calidad de producto</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block">
                    <p>Desarrollamos proyectos para la actualización y modernización de procesos industriales integrando:</p>
                </div>
                <div className="block" style={{ paddingBottom: '2rem' }}>
                    <img src={imgDesarrollo} style={{ width: '80%' }}></img>
                </div>
                <Certificacion></Certificacion>
                <PiePagina></PiePagina>
            </div>

        </div>
    );
}
export default Soluciones