import React from "react";

import { Galleria } from "primereact/galleria";
import PiePagina from "../pie/PiePagina";
import {Button} from 'primereact/button';
import {useNavigate} from 'react-router-dom'

import './css/conocenos.css';

import imgTituloIng from './img/ingenio-23.png'
import imgIng from './img/ingenio-24.png'
import imgTituloIngA from './img/ingenio-25.png'
import imgIngA from './img/ingenio-26.png'
import imgLuz from "./img/banner luz.png"
import imgMis from './img/mision.png';
import imgVis from './img/vision.png';
import imgVal from './img/valores.png';

function Conocenos() {

    const navigate = useNavigate();

    const urlInicio = ()=>{
        window.scrollTo(0, 0);
        navigate('/inicio');
    }

    const arrayImagen = [
        {
            url: imgTituloIng
        },
        {
            url: imgIng
        },
        {
            url: imgTituloIngA
        },
        {
            url: imgIngA
        },
    ];

    const templateImgCarrusel = (data) => {
        return (
            <img src={data.url} alt={"test"} style={{ width: "100%", borderRadius: '10px' }} />

        )
    }

    return (
        <div className="card">
            <div className="card-container" style={{ backgroundColor: '#111c4e' }}>
                <div className="block">
                    <img src={imgLuz} style={{ width: '100%' }}></img>
                </div>
                <div className="block textoTitulo">
                    <div>Conócenos</div>
                </div>
                <div className="block">
                    <img src={imgLuz} style={{ width: '100%' }}></img>
                </div>
            </div>
            <div className="card-container" style={{ backgroundColor: '#f9f9f9', paddingTop: '6rem' }}>
                <div className="block" style={{ paddingLeft: '2rem', paddingRight: '2rem' }}>
                    <div className="grid">
                        <div className="sm:col-6">
                            <Galleria
                                value={arrayImagen}
                                showThumbnails={false}
                                showIndicators
                                showItemNavigators
                                showIndicatorsOnItem={true}
                                indicatorsPosition={'bottom'}
                                item={templateImgCarrusel}
                                autoPlay transitionInterval={5000}
                                circular
                                numVisible={arrayImagen.length}
                            />

                        </div>
                        <div className="sm:col-6" style={{ alignContent: 'center' }}>
                            <div className="flex align-content-center flex-wrap" style={{ minHeight: '100%' }}>
                                <p className="textoParrafoConocenos">Redefinimos la ingeniería con la motivación y pasión para desarrollar proyectos efectivos que cumplen con los propósitos de clientes y socios, cosolidando un equipo experto, innovador y altamente especializado.</p>
                            </div>
                        </div>
                    </div>
                    <div className="block">
                        <p className="textoSubtitulo">Misión</p>
                    </div>
                    <div className="grid">
                        <div className="sm:col-3">
                            <img className="imgVal" src={imgMis} ></img>
                        </div>
                        <div className="sm:col-9">
                            <div className="flex align-content-center flex-wrap" style={{ minHeight: '100%' }}>
                                <p className="textoParrafoConocenos">Creemos en un futuro revolucionario, sustentable y costenible, por ello innovacmos y creamos tecnologías para la industria, brindamos servicios integrales en eingeniería, sistemas y desarrollo digital, comunicaciones y electrónica, garantizando el cumplimiento del propósiot, la calidad y eficiencia en cada proyecto</p>
                            </div>
                        </div>
                    </div>
                    <div className="block">
                        <p className="textoSubtitulo">Visión</p>
                    </div>
                    <div className="grid">
                        <div className="sm:col-9">
                            <div className="flex align-content-center flex-wrap" style={{ minHeight: '100%' }}>
                                <p className="textoParrafoConocenos">
                                    Consolidarnos como líder en el campo de validacion y pruebas del sector industrial, OEMs y proveedores, con tecnologías sustentables y sostenibles que generen un redimmiento óptimo para nuestros clientes y socios a través de la mejora continua.<br />
                                    Expandir nuestra red de colaboradores y socios para generar oportunidades de investigación y desarrollo de tecnologías automotrices efectivas tanto en la Tierra como en el espacio
                                </p>
                            </div>
                        </div>
                        <div className="sm:col-3">
                            <img className="imgVal" src={imgVis} ></img>
                        </div>
                    </div>
                    <div className="block">
                        <p className="textoSubtitulo">Valores</p>
                    </div>
                    <div className="grid">
                        <div className="col-12 sm:col-1">
                            <div className="flex flex-wrap align-content-center justify-content-center" style={{minHeight:'100%'}}>
                                <img className='imgVal' src={imgVal} ></img>
                            </div>
                        </div>
                        <div className="lg:col-11">
                            <div className="flex flex-wrap align-content-center" style={{minHeight:'100%', minWidth:'100%'}}>
                            <p className="textoParrafoConocenos"> Honestidad, integridad, compromiso y rectitud son necesarias para la estabilidad de una organización, es la base para su desarrollo.</p>
                            </div>
                        </div>
                        <div className="col-12 sm:col-1">
                            <div className="flex flex-wrap align-content-center justify-content-center" style={{minHeight:'100%'}}>
                                <img className='imgVal' src={imgVal} ></img>
                            </div>
                        </div>
                        <div className="lg:col-11">
                            <div className="flex flex-wrap align-content-center" style={{minHeight:'100%'}}>
                            <p className="textoParrafoConocenos"> Lealtad, las palabras de un hombre son como sus huellas, puedes seguirlas donde quiera que él vaya.</p>
                            </div>
                        </div>
                        <div className="col-12 sm:col-1">
                            <div className="flex flex-wrap align-content-center justify-content-center" style={{minHeight:'100%'}}>
                                <img className='imgVal' src={imgVal} ></img>
                            </div>
                        </div>
                        <div className="lg:col-11">
                            <div className="flex flex-wrap align-content-center" style={{minHeight:'100%'}}>
                            <p className="textoParrafoConocenos">Solidaridad, ayuda a tus compañeros en cualquier oportunidad, y si la oportunidad no surg, sal de camino para encontrarla.</p>
                            </div>
                        </div>
                        <div className="col-12 sm:col-1">
                            <div className="flex flex-wrap align-content-center justify-content-center" style={{minHeight:'100%'}}>
                                <img className='imgVal' src={imgVal} ></img>
                            </div>
                        </div>
                        <div className="lg:col-11">
                            <div className="flex flex-wrap align-content-center" style={{minHeight:'100%'}}>
                            <p className="textoParrafoConocenos">Labor inteligente, la experiencia y el trabajo duro pueden superar al talento nato.</p>
                            </div>
                        </div>
                        <div className="col-12 sm:col-1">
                            <div className="flex flex-wrap align-content-center justify-content-center" style={{minHeight:'100%'}}>
                                <img className='imgVal' src={imgVal} ></img>
                            </div>
                        </div>
                        <div className="lg:col-11">
                            <div className="flex flex-wrap align-content-center" style={{minHeight:'100%'}}>
                            <p className="textoParrafoConocenos"> Efectividad sostenible y sustentable, son los objetivos por lograr en cada proyecto.</p>
                            </div>
                        </div>
                        <div className="col-12 sm:col-1">
                            <div className="flex flex-wrap align-content-center justify-content-center" style={{minHeight:'100%'}}>
                                <img className='imgVal' src={imgVal} ></img>
                            </div>
                        </div>
                        <div className="lg:col-11">
                            <div className="flex flex-wrap align-content-center" style={{minHeight:'100%'}}>
                            <p className="textoParrafoConocenos">Mejora continua, en cada proyecto encontramos innovaciones y mejoras que beneficien a socios y clientes.</p>
                            </div>
                        </div>
                        <div className="col-12 lg:col-12">
                            <div className="flex flex-wrap align-content-center justify-content-center" style={{marginBottom:'2rem'}}>
                                <Button onClick={urlInicio}>Inicio</Button>
                            </div>
                        </div>
                    </div>
                </div>
                
                <PiePagina></PiePagina>
            </div>
        </div>
    )
}
export default Conocenos;