import React from "react";
import imgFace from './img/ico fb 2.png';
import imgInst from './img/ico ig 2.png';
import imgIn from './img/ico in 2.png';
import imgBlog from './img/ico bl.png';

function PiePagina() {
    return (
        <div className="card" style={{ backgroundColor: '#111c4e', color: 'white' }}>
            <div className="grid">
                <div className="sm:col-8" style={{ paddingLeft:'4rem', textAlign:'left'}}>
                    <p>Emiliano Zapata No. 62 C, col. Santiago Atepetlac, delegación Gustavo A. Madero, CDMX, c.p. 07640, México<br />
                        <br/>
                        Tel. 55 1543 1457
                        <br/>
                        wa: 55 3123 5622</p>
                </div>
                <div className="sm:col-4" style={{textAlign:'right', paddingLeft:'4rem'}}>
                    <div className="inline-block" style={{ padding: '0.5rem' }}>
                        <a href="https://www.facebook.com/CydoniaMexico"><img src={imgFace}  style={{ width: '4rem' }}></img></a>
                    </div>
                    <div className="inline-block" style={{ padding: '0.5rem' }}>
                        <a href='https://www.instagram.com/cydoniamexico/'><img src={imgInst} style={{ width: '4rem' }}></img></a>
                    </div>
                    <div className="inline-block" style={{ padding: '0.5rem' }}>
                        <a href="https://www.linkedin.com/company/cydoniamx"><img src={imgIn} style={{ width: '4rem' }}></img></a>
                    </div>
                </div>

            </div>
            <div className="grid">
                <div className="sm:col-6" style={{textAlign:'left', paddingLeft:'4rem'}}>
                    <p>Email: proyectos@cydoniamx.com</p>
                </div>
                <div className="sm:col-6" style={{textAlign:'right', paddingRight:'2rem', paddingLeft:'4rem'}} >
                    <p>Aviso de privacidad</p>
                </div>
            </div>
        </div>
    )
}
export default PiePagina;