import React, { useEffect } from "react";
import pdfA from './pdf/pdfA.pdf';
function PdfA() {
    useEffect(() => {
        const link = document.createElement('a');
        link.href = pdfA;
        link.target = '_blank';
        link.download = 'Angel Castro - Cydonia Engineering.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }, [])
}
export default PdfA;