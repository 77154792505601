import React from "react";

import imgLuz from './img/banner luz.png';
import imgSistemas from './img/sistemas.png';
import imgComunicacion from './img/comunicaciones.png';
import imgValidacion from './img/validacion.png';
import imgsoluciones from './img/soluciones.png';

import './css/proyectosIntegrales.css'
import PiePagina from "../pie/PiePagina";
import { Button } from "primereact/button";
import { useNavigate } from 'react-router-dom'

function ProyectosIntegrales() {
    const navigate = useNavigate();
    const redireccionar = (url) => {
        window.scrollTo(0, 0);
        navigate(url);
    }
    return (
        <div className="card">
            <div className="card-container" style={{ backgroundColor: '#111c4e' }}>
                <div className="block">
                    <img src={imgLuz} style={{ width: '100%' }}></img>
                </div>
                <div className="block textoTitulo">
                    <div>Proyectos Integrales</div>
                </div>
                <div className="block">
                    <img src={imgLuz} style={{ width: '100%' }}></img>
                </div>
            </div>
            <div className="card-container" style={{ backgroundColor: '#f9f9f9', paddingTop: '6rem', paddingBottom: '1rem' }}>
                <div className="block" style={{ paddingLeft: '3rem', paddingRight: '3rem' }}>
                    <div className="flex align-content-center flex-wrap" style={{ minHeight: '100%' }}>
                        <p className="textoParrafoProyectos">Al aliarse con Cydonia Engineering para desarrollar sus proyectos, cuenta con la garantía de la correcta ejecución, seguimiento y mejora de los procesos, que tiene como beneficio la reducción de costos y seguridad de respuesta inmediata a problemas concretos, consiguiendo una mayor efectividad y productividad.</p>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 sm:col-6">
                        <div className="block" style={{ paddingLeft: '3rem', paddingRight: '3rem' }}>
                            <p className="textoSubTitulos">Validación y Verificación</p>
                            <img src={imgValidacion} style={{ width: '40%' }}></img>
                            <p className="textoParrafoSubProyectos">Validamos que un producto, servicio o sistema cumple con las necesidades de clientes y usuarios. Se desarrollan proyectos de verificación para garantizar los estándares de calidad requeridos, así como el cumplimiento de especificaciones o condiciones del propio proyecto y/o para el cumplimiento de alguna regulación y obtención de certificación.</p>
                            <Button
                                onClick={() => {
                                    redireccionar('/verificacion')
                                }}
                            >Explorar más</Button>
                        </div>
                    </div>
                    <div className="col-12 sm:col-6">
                        <div className="block" style={{ paddingLeft: '3rem', paddingRight: '3rem' }}>
                            <p className="textoSubTitulos">Soluciones Industriales</p>
                            <img src={imgsoluciones} style={{ width: '40%' }}></img>
                            <p className="textoParrafoSubProyectos">Contamos con servicios de consultoria integral para realizar el diagnóstico e identificación de problemas, fallas y áreas de oportunidad en los distintos procesos de la industria para elaborar planes de mejora a la medida de sus necesidades, requerimientos y objetivos que aumenten la productividad, mejoren la calidad de procesos y producto final con una reducción de costos y riesgos.</p>
                            <Button
                                onClick={() => {
                                    redireccionar('/soluciones')
                                }}
                            >Explorar más</Button>
                        </div>
                    </div>
                    <div className="col-12 sm:col-6">
                        <div className="block" style={{ paddingLeft: '3rem', paddingRight: '3rem' }}>
                            <p className="textoSubTitulos">Sistemas y Desarrollo Digital</p>
                            <img src={imgSistemas} style={{ width: '40%' }}></img>
                            <p className="textoParrafoSubProyectos">Consientes de lo avances tecnológicos, innovamos para diseñar e implementar proyectos en sistemas y desarrollo digital que permiten a las empresas migrar a la digitalización de sus procesos en forma escalada, ágil y a la medida de sus necesidades, brindando mayor productividad, redicción de costos, respuesta oportuna ante problemáticas específicas y la garantía de apuntar siempre a la calidad y mejora continua. </p>
                            <Button
                            onClick={()=>{
                                redireccionar('/sistemas')
                            }}>Explorar más</Button>
                        </div>
                    </div>
                    <div className="col-12 sm:col-6">
                        <div className="block" style={{ paddingLeft: '3rem', paddingRight: '3rem' }}>
                            <p className="textoSubTitulos">Comunicaciones y Electrónica</p>
                            <img src={imgComunicacion} style={{ width: '40%' }}></img>
                            <p className="textoParrafoSubProyectos">Ejecutamos proyectos de desarrollo e implementaciones integrales de sistemas electrónicos especializafos para la industria y laboratorios, desde el diseño y desarrollo de prototipos de hardware y software hasta la implementación, el mantenimiento preventivo y correctico de los mismos, de acuerdo con los requerimientos del clente.</p>
                            <Button
                            onClick={()=>{
                                redireccionar('/comunicaciones')
                            }}>Explorar más</Button>
                        </div>
                    </div>

                </div>

            </div>
            <PiePagina></PiePagina>
        </div>
    );
}
export default ProyectosIntegrales;