import React from "react";

import { Galleria } from "primereact/galleria";
import PiePagina from "../pie/PiePagina";
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom'

import './css/smooth.css';

import imgsm1 from './img/smooth.png';
import imgsm2 from './img/smooth_1.png';
import imgw1 from './img/whatsapp.png';
import imgar1 from './img/arumajiro 1.png';
import imgar2 from './img/arumajiro 2.png';
import imgar3 from './img/arumajiro 3.png';
import imgav1 from './img/avp247 1.png';
import imgav2 from './img/avp247 2.png';
import imgav3 from './img/avp247 3.png';
import imgfv1 from './img/faavos 1.png';
import imgfv2 from './img/faavos 2.png';
import imgfv3 from './img/faavos 3.png';


function Smooth() {

    const navigate = useNavigate();

    const arrayImagen = [
        {
            url: imgsm1
        },
        {
            url: imgsm2
        },
        {
            url: imgw1
        }
    ]
    
    const arrayArumajiro = [
        {
            url:imgar1
        },
        {
            url:imgar2
        },
        {
            url:imgar3
        }

    ]
    const arrayAvp247 = [
        {
            url:imgav1
        },
        {
            url:imgav2
        },
        {
            url:imgav3
        }
    ]
    const arrayFaavos = [
        {
            url:imgfv1
        },
        {
            url:imgfv2
        },
        {
            url:imgfv3
        },
    ]
    const templateImgCarrusel = (data) => {
        return (
            <img src={data.url} alt={"test"} style={{ width: "100%" }} />

        )
    }
    return (
        <div class="card ">
            <div class="card-container " style={{ backgroundColor: "#f9f9f9" }}>
                <div className='cardBack'>
                    <img src={imgsm1} style={{ width: '100%' }}></img>
                </div>

                
                <div className="grid" style={{}}>
                    <div className="col-12 sm:col-12">
                        <p style={{ "font-size": "4rem", "text-align": "center", marginBottom: "0px",  marginTop: "0px", color:"#0524F2" }}><b>WHEREVER WE GO</b></p>

                    </div>
                    <div className="col-12 sm:col-12">
                        <p style={{ "font-size": "28px", "marginTop": "0px" }}>Smooth Operator es una plataforma multipropósito diseñada para llegar a lugares de difícil acceso en distintos entornos y proporcionar el auxilio requerido con el menor riesgo posible. </p>
                        <p style={{ "font-size": "28px" }}>Hasta el momento contamos con tres versiones en desarrollo: </p>
                    </div>
                    <div className="col-12 sm:col-6">
                        <div className="block" style={{ paddingLeft: '3rem', paddingRight: '3rem' }}>
                            <p className="textoSubTitulos" style={{ color: "#F84318" }}> ARUMAJIRO </p>
                            <p className="textoParrafoSubProyectos" style={{ "font-size": "28px", paddingBottom:"1rem" }}>ARUMAJIRO es capaz de explorar lugares subterráneos de difícil acceso para el ser humano, como en la minería, exploraciones en cavernas angostas, excavaciones arqueológicas, entre otras.</p>
                            <div className='block cardBack' style={{width:"30vw", margin:"auto"}}>
                                <Galleria value={arrayArumajiro} 
                                    showThumbnails={false} 
                                    showIndicators
                                    showIndicatorsOnItem={true} 
                                    indicatorsPosition={'bottom'} 
                                    item={templateImgCarrusel} 
                                    autoPlay transitionInterval={5000}
                                    circular
                                    numVisible={arrayImagen.length}
                                    //sizes={{width:"90%"}}
                                    />
                            </div>
                            <p className="textoParrafoSubProyectos" style={{ "font-size": "28px" }}>El GPS permite mostrar la ubicación exacta de ARUMAJIRO, los detectores integrados enviar información sobre el ambiente explorado para determinar los riesgos para la salud humana, además cuenta con servicios en la nube para consultar la información obtenida de los detectores y videos en su trayecto para su posterior análisis. </p>
                            <p className="textoParrafoSubProyectos" style={{ "font-size": "28px", "marginTop": "3rem", "marginBottom": "4rem" }}>Características:</p>
                            <ul style={{ "font-size": "28px" }}>
                                <li style={{ "textAlign": "left" }}>GPS </li>
                                <li style={{ "textAlign": "left" }}>Cámara de 360° </li>
                                <li style={{ "textAlign": "left" }}>Visón térmica, nocturna e infrarroja </li>
                                <li style={{ "textAlign": "left" }}>Luces de alta potencia </li>
                                <li style={{ "textAlign": "left" }}>Luz estroboscópica color ámbar </li>
                                <li style={{ "textAlign": "left" }}>Detector de gases </li>
                                <li style={{ "textAlign": "left" }}>Detector de temperatura y presión </li>
                                <li style={{ "textAlign": "left" }}>Actuador </li>
                                <li style={{ "textAlign": "left" }}>Control inalámbrico </li>
                                <li style={{ "textAlign": "left" }}>Batería recargable </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 sm:col-6">
                        <div className="block" style={{ paddingLeft: '3rem', paddingRight: '3rem' }}>
                            <p className="textoSubTitulos" style={{ color: "#66F205" }}>AVP247</p>
                            <p style={{ "font-size": "28px", "text-align": "justify" }}>El siguiente nivel de seguridad y protección para empresas y hogares es AVP247, puede configurar las cercas geográficas para mantener segura un área determinada. </p>
                            <div className='block cardBack' style={{width:"30vw", margin:"auto"}}>
                                <Galleria value={arrayAvp247} 
                                    showThumbnails={false} 
                                    showIndicators
                                    showIndicatorsOnItem={true} 
                                    indicatorsPosition={'bottom'} 
                                    item={templateImgCarrusel} 
                                    autoPlay transitionInterval={5000}
                                    circular
                                    numVisible={arrayImagen.length}
                                    //sizes={{width:"90%"}}
                                    />
                            </div>
                            <p style={{ "font-size": "28px", "text-align": "justify" }}>El reconocimiento facial permite consultar los permisos autorizados y enviar una alerta de seguridad ante la presencia de personas no autorizadas y proteger lo más importante. la visión nocturna, infrarroja y térmica permiten las operaciones de seguridad incluso en la noche con poca iluminación, brindando protección 24 horas, los 7 días de la semana. </p>
                            <p style={{ "font-size": "28px", "text-align": "justify" }}>Características:</p>
                            <ul compact style={{ "font-size": "28px" }}>
                                <li style={{ textAlign: "left" }}>Recorridos automatizados por medio de GLONASS </li>
                                <li style={{ textAlign: "left" }}>Cámara de 360° </li>
                                <li style={{ textAlign: "left" }}>Visón térmica, nocturna e infrarroja </li>
                                <li style={{ textAlign: "left" }}>Reconocimiento facial para acceso al área. </li>
                                <li style={{ textAlign: "left" }}>Luces dirigibles </li>
                                <li style={{ textAlign: "left" }}>Speakers o altavoces </li>
                                <li style={{ textAlign: "left" }}>Micrófono integrado </li>
                                <li style={{ textAlign: "left" }}>Control inalámbrico </li>
                                <li style={{ textAlign: "left" }}>Batería recargable </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 sm:col-6">
                        <div className="block" style={{ paddingLeft: '3rem', paddingRight: '3rem' }}>
                            <p className="textoSubTitulos" style={{ color: "#CC0000" }}>FAAVOS</p>
                            <p className="textoParrafoSubProyectos" style={{ "font-size": "28px" }}>FAAVOS es la versión de Smooth Operator que brinda soporte en rescate y resguardo civil tras algún tipo de siniestro como un temblor, incendio, etc. Al ser capaz de acceder a lugares estrechos y de riesgos altos puede brindar la primera ayuda para salvaguardar la vida humana.</p>
                            <p className="textoParrafoSubProyectos" style={{ "font-size": "28px" }}>El GPS muestra su ubicación exacta para la localización y extracción de víctimas de un siniestro, mantiene el canal de comunicación para brindar la primera ayuda médica con el IFAK. La información se almacena en los servicios de la nube para su posterior consulta y mejora de los procedimientos de rescate. </p>
                            <p className="textoParrafoSubProyectos" style={{ "font-size": "28px", "marginTop": "0px", "marginBottom": "0px" }}>Características:</p>
                            <ul compact style={{ "font-size": "28px" }}>
                                <li style={{ textAlign: "left" }}>Torreta de seguridad </li>
                                <li style={{ textAlign: "left" }}>GPS</li>
                                <li style={{ textAlign: "left" }}>Cámara de 360°</li>
                                <li style={{ textAlign: "left" }}>Visón térmica y nocturna</li>
                                <li style={{ textAlign: "left" }}>Detector de gases </li>
                                <li style={{ textAlign: "left" }}>Kit de primeros auxilios individual (IFAK)</li>
                                <li style={{ textAlign: "left" }}>Dispositivos anti-fuego</li>
                                <li style={{ textAlign: "left" }}>Speakers o altavoces</li>
                                <li style={{ textAlign: "left" }}>Canal de comunicación abierta </li>
                                <li style={{ textAlign: "left" }}>Actuador </li>
                                <li style={{ textAlign: "left" }}>Control inalámbrico </li>
                                <li style={{ textAlign: "left" }}>Batería recargable</li>
                            </ul>

                        </div>
                    </div>
                    
                    <div className="col-12 sm:col-6">
                        <div className="block" style={{ paddingLeft: '3rem', paddingRight: '3rem', paddingTop:"8rem" }}>
                            <div className='block cardBack' style={{width:"30vw", margin:"auto"}}>
                                <Galleria value={arrayFaavos} 
                                    showThumbnails={false} 
                                    showIndicators
                                    showIndicatorsOnItem={true} 
                                    indicatorsPosition={'bottom'} 
                                    item={templateImgCarrusel} 
                                    autoPlay transitionInterval={5000}
                                    circular
                                    numVisible={arrayImagen.length}
                                    sizes={{width:"90%", paddingTop:"8rem"}}
                                    />
                            </div>
                        </div>
                        <div className="block" style={{ paddingLeft: '3rem', paddingRight: '3rem', paddingTop: '3rem' }}>
                            <table border="5 solid" style={{ "border-color": "#1E18EF", "background-color": "#f9f9f9", "border-radius": "25px" }}>
                                <tr>
                                        <p className="textoParrafoSubProyectos" background-color="#090909" style={{ "padding": "35px" }}>Cada modelo posee las caracteristicas esenciales para un optimo funcionamiento, ademas cuentan con la facilidad de adaptarse para integrar aditamentos adicionales de acuerdo a los requerimientos de cada proyecto.</p>
                                </tr>
                            </table>
                        </div>
                        <div style={{ paddingTop: '3rem', textAlign:"center" }}>
                            <a  href="https://wa.me/525531235622">
                                <img src={imgw1} style={{ width: '10rem' }}></img>
                            </a>
                        </div>
                        <div>
                            <p style={{ fontSize: "2.5rem" }}>Contacta con uno de nuestros asesores para mayor informacion</p>
                        </div>
                    </div>
                    
                </div>
                <div className="block" style={{ paddingTop: '3rem' }}><PiePagina></PiePagina></div>

            </div>
        </div >
    )
}
export default Smooth;