
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { useState } from 'react';
import Home from './componentes/home/Home';
import Inicio from './componentes/inicio/Inicio';
import Conocenos from './componentes/conocenos/Conocenos';
import ProyectosIntegrales from './componentes/proyectos/proyectosIntegrales';
import Validacion from './componentes/proyectos/validacion';
import Soluciones from './componentes/proyectos/soluciones';
import Sistemas from './componentes/proyectos/sistemas';
import Comunicacion from './componentes/proyectos/comunicacion';
import Contacto from './componentes/contacto/contacto';
import Smooth from './componentes/smooth/smooth';
import ContactoE from './componentes/pdfs/pdfA';
import ContactoA from './componentes/pdfs/pdfE';
import ContactoM from './componentes/pdfs/pdfM';
import ContactoB from './componentes/pdfs/pdfB';
import PdfA from './componentes/pdfs/pdfA';
import PdfE from './componentes/pdfs/pdfE';
import PdfM from './componentes/pdfs/pdfM';
import PdfB from './componentes/pdfs/pdfB';

function App() {
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home></Home>}>
          <Route index element={<Inicio></Inicio>}></Route>
          <Route path='inicio' element={<Inicio></Inicio>}></Route>
          <Route path='conocenos' element={<Conocenos></Conocenos>}></Route>
          <Route path='proyectos' element={<ProyectosIntegrales></ProyectosIntegrales>}></Route>
          <Route path='verificacion' element={<Validacion></Validacion>}></Route>
          <Route path='soluciones' element={<Soluciones></Soluciones>}></Route>
          <Route path='sistemas' element={<Sistemas></Sistemas>}></Route>
          <Route path='comunicaciones' element={<Comunicacion></Comunicacion>}></Route>
          <Route path='contacto' element={<Contacto></Contacto>}></Route>
          <Route path='smooth' element={<Smooth></Smooth>}></Route>
          <Route path='contacto-eduardo-colin' element={<PdfE></PdfE>}></Route>
          <Route path='contacto-angel-castro' element={<PdfA></PdfA>}></Route>
          <Route path='contacto-marco-gutierrez' element={<PdfM></PdfM>}></Route>
          <Route path='Breochure-Cydonia-Engineering' element={<PdfB></PdfB>}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
