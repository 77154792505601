import React from "react";

import imgLuz from "./img/banner luz.png";
import imgLogoCyd from './img/logo cydonia sin fondo.png';

import './css/contacto.css';
import imgWhat from './img/ico wh 2.png';
import imgMail from './img/ico mail.png';
import imgTel from './img/ico tel.png';
import imgDialog from './img/dialogo.png';
import PiePagina from "../pie/PiePagina";
import imgIn from './img/ico in 2.png';
import imgIg from './img/ico ig 2.png';
import imgFb from './img/ico fb 2.png';

function Contacto() {
    return (
        <div className="card">
            <div className="card-container">
                <div className="block" style={{ backgroundColor: '#111c4e' }}>
                    <img src={imgLuz} style={{ width: '100%' }}></img>
                </div>
                <div className="block textoTitulo">
                    <div>Contacto</div>
                </div>
                <div className="block" style={{ backgroundColor: '#111c4e' }}>
                    <img src={imgLuz} style={{ width: '100%' }}></img>
                </div>
            </div>
            <div className="card-container" style={{ backgroundColor: '#f9f9f9' }}>
                <div className="block" style={{ paddingTop: '3rem', paddingBottom: '3rem' }}>
                    <img src={imgLogoCyd} className="logoIcono"></img>
                </div>
            </div>
            <div className="block" style={{ backgroundColor: '#f9f9f9' }}>
                <div className="grid">
                    <div className="col-12 sm:col-6">
                        <div className="block contacto-margin" >
                            <div className="grid">
                                <div className="col-2" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                                    <img src={imgMail} style={{ width: '100%' }}></img>
                                </div>
                                <div className="col-10">
                                    <div className="flex flex-wrap card-container align-items-center " style={{ minHeight: '100%' }}>
                                        <p className="txtContacto">contacto@cydoniamx.com</p>
                                    </div>

                                </div>
                                <div className="col-2" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                                    <img src={imgTel} style={{ width: '100%' }}></img>
                                </div>
                                <div className="col-10">
                                    <div className="flex flex-wrap card-container align-items-center " style={{ minHeight: '100%' }}>
                                        <p className="txtContacto">+ 52 (55) 4043 2455</p>
                                    </div>
                                </div>
                                <div className="col-2" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                                    <img src={imgWhat} style={{ width: '100%' }}></img>
                                </div>
                                <div className="col-10">
                                    <div className="flex flex-wrap card-container align-items-center " style={{ minHeight: '100%' }}>
                                        <p className="txtContacto">Iniciar conversación</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 sm:col-6">
                        <img src={imgDialog} style={{ width: '90%' }}></img>
                    </div>
                </div>
                <div className="block contacto-margin" style={{ paddingBottom: '2rem' }}>
                    <div class="grid">
                        <div class="col-6 sm:col-4">
                            <div className="grid">
                                <div className="col-4">
                                    <img src={imgIn} style={{ width: '100%' }}></img>
                                </div>
                                <div className="col-6">
                                    <div className="flex flex-wrap card-container align-items-center " style={{ minHeight: '100%' }}>
                                        <p className="txtContacto">Cydonia Engineering</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 sm:col-4">
                            <div className="grid">
                                <div className="col-4">
                                    <img src={imgFb} style={{ width: '100%' }}></img>
                                </div>
                                <div className="col-6">
                                    <div className="flex flex-wrap card-container align-items-center " style={{ minHeight: '100%' }}>
                                        <p className="txtContacto">@Cydonia Engineering</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 sm:col-4">
                            <div className="grid">
                                <div className="col-4">
                                    <img src={imgIg} style={{ width: '100%' }}></img>
                                </div>
                                <div className="col-6">
                                    <div className="flex flex-wrap card-container align-items-center " style={{ minHeight: '100%' }}>
                                        <p className="txtContacto">@Cydonia Engineering</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PiePagina></PiePagina>
            </div>
        </div>
    );
}
export default Contacto